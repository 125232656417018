import React, { useContext } from "react";
import { AccessibilityContext } from "../context/AccessibilityContext";
import { Button, Box, Typography } from "@mui/material";
import TextIncreaseIcon from '@mui/icons-material/TextIncrease';
import TextDecreaseIcon from '@mui/icons-material/TextDecrease';
import ContrastIcon from '@mui/icons-material/Contrast';
import FormatColorResetIcon from '@mui/icons-material/FormatColorReset';
import LinkIcon from '@mui/icons-material/Link';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import FontDownloadIcon from '@mui/icons-material/FontDownload';

const AccessibilityMenu = ({ content }) => {
    const { accessibilityOptions, updateOptions } = useContext(AccessibilityContext);

    const MenuButton = ({ onClick, startIcon, children, active }) => (
        <Button
            onClick={onClick}
            startIcon={startIcon}
            fullWidth
            sx={{
                justifyContent: "flex-start",
                color: active ? "#0F3460" : "#333",
                backgroundColor: active ? "rgba(15, 52, 96, 0.1)" : "transparent",
                padding: "12px 16px",
                textAlign: "start",
                borderRadius: 0,
                borderBottom: "1px solid #E0E0E0",
                "&:hover": {
                    backgroundColor: "rgba(15, 52, 96, 0.1)",
                },
                "& .MuiButton-startIcon": {
                    marginRight: 2,
                },
            }}
        >
            {children}
        </Button>
    );

    const handleScalingFactorIncrease = () => {
        updateOptions(prev => ({
            ...prev,
            scalingFactor: Math.min(prev.scalingFactor + 0.1, 2)
        }));
    };

    const handleScalingFactorDecrease = () => {
        updateOptions(prev => ({
            ...prev,
            scalingFactor: Math.max(prev.scalingFactor - 0.1, 0.8)
        }));
    };

    const handleFilterToggle = () => {
        updateOptions(prev => ({
            ...prev,
            filter: prev.filter === "none" ? "grayscale(1)" : "none"
        }));
    };

    const handleHighContrastToggle = () => {
        updateOptions(prev => ({
            ...prev,
            contrast: prev.contrast === "high" ? "normal" : "high"
        }));
    };

    const handleLowContrastToggle = () => {
        updateOptions(prev => ({
            ...prev,
            contrast: prev.contrast === "low" ? "normal" : "low"
        }));
    };

    const handleHighlightLinksToggle = () => {
        updateOptions(prev => ({
            ...prev,
            highlightLinks: !prev.highlightLinks
        }));
    };

    const handleFontFamilyToggle = () => {
        updateOptions(prev => ({
            ...prev,
            fontFamily: prev.fontFamily === "readable" ? "inherit" : "readable"
        }));
    };

    const handleReset = () => {
        updateOptions({
            scalingFactor: 1,
            filter: "none",
            contrast: "normal",
            highlightLinks: false,
            fontFamily: "inherit",
        });
    };

    return (
        <Box>
            <Box sx={{ 
                p: 2,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between"
            }}>
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                    {content.title}
                </Typography>
            </Box>

            <Box sx={{ py: 1 }}>
                <MenuButton
                    onClick={handleScalingFactorIncrease}
                    startIcon={<TextIncreaseIcon />}
                >
                    {content.increaseText}
                </MenuButton>

                <MenuButton
                    onClick={handleScalingFactorDecrease}
                    startIcon={<TextDecreaseIcon />}
                >
                    {content.decreaseText}
                </MenuButton>

                <MenuButton
                    onClick={handleFilterToggle}
                    startIcon={<FormatColorResetIcon />}
                    active={accessibilityOptions.filter !== "none"}
                >
                    {accessibilityOptions.filter === "none" ? content.enableGrayscale : content.disableGrayscale}
                </MenuButton>

                <MenuButton
                    onClick={handleHighContrastToggle}
                    startIcon={<ContrastIcon />}
                    active={accessibilityOptions.contrast === "high"}
                >
                    {accessibilityOptions.contrast === "high" ? content.disableHighContrast : content.enableHighContrast}
                </MenuButton>

                <MenuButton
                    onClick={handleLowContrastToggle}
                    startIcon={<ContrastIcon />}
                    active={accessibilityOptions.contrast === "low"}
                >
                    {accessibilityOptions.contrast === "low" ? content.disableLowContrast : content.enableLowContrast}
                </MenuButton>

                <MenuButton
                    onClick={handleHighlightLinksToggle}
                    startIcon={<LinkIcon />}
                    active={accessibilityOptions.highlightLinks}
                >
                    {accessibilityOptions.highlightLinks ? content.disableHighlightLinks : content.enableHighlightLinks}
                </MenuButton>

                <MenuButton
                    onClick={handleFontFamilyToggle}
                    startIcon={<FontDownloadIcon />}
                    active={accessibilityOptions.fontFamily === "readable"}
                >
                    {accessibilityOptions.fontFamily === "readable" ? content.disableReadableFont : content.enableReadableFont}
                </MenuButton>

                <MenuButton
                    onClick={handleReset}
                    startIcon={<RestartAltIcon />}
                >
                    {content.resetSettings}
                </MenuButton>
            </Box>
        </Box>
    );
};

export default AccessibilityMenu;
