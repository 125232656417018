import { TextField } from "@mui/material";
import { styled } from "@mui/system";

const StyledTextField = styled(TextField, {
  shouldForwardProp: prop => prop !== 'accessibilityOptions'
})(({ theme, accessibilityOptions }) => ({
  backgroundColor: accessibilityOptions.contrast === "high" ? "#000" : accessibilityOptions.contrast === "low" ? "#C2D3FC" : theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  border: accessibilityOptions.contrast === "high" ? "1px solid #40C090" : accessibilityOptions.contrast === "low" ? "1px solid #666666" : "none",
  ...theme.typography.body2,
  width: '240px',
  '& .MuiInputBase-input': {
    color: accessibilityOptions.contrast === "high" ? "#40C090" : accessibilityOptions.contrast === "low" ? "#666666" : "inherit",
    textAlign: 'left',
  },
  '& .MuiInputLabel-root': {
    color: accessibilityOptions.contrast === "high" ? "#40C090" : accessibilityOptions.contrast === "low" ? "#666666" : "#333333",
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: accessibilityOptions.contrast === "high" ? "#40C090" : accessibilityOptions.contrast === "low" ? "#666666" : "inherit",
    },
    '&:hover fieldset': {
      borderColor: accessibilityOptions.contrast === "high" ? "#40C090" : accessibilityOptions.contrast === "low" ? "#666666" : "inherit",
    },
    '&.Mui-focused fieldset': {
      borderColor: accessibilityOptions.contrast === "high" ? "#40C090" : accessibilityOptions.contrast === "low" ? "#666666" : "inherit",
    },
  },
}));

const InputField = ({ accessibilityOptions, ...props }) => (
  <StyledTextField accessibilityOptions={accessibilityOptions} {...props} />
);

export default InputField;