import React, { useContext} from "react";
import { useMediaQuery, Grid, Box, Portal } from "@mui/material";
import HeroBanner from "../components/HeroBanner";
import About from "../components/About";
import Companies from "../components/Companies";
import Recommendations from "../components/Recommendations";
import ContactMe from "../components/ContactMe";
import Navbar from "../components/NavBar";
import Scopes from "../components/Scopes";
import Footer from "../components/Footer";
import FloatingBar from "../components/FloatingBar";
import { useContent } from "../components/reusable/useContent";
import LoadingAnimation from "../components/reusable/LoadingAnimation";
import { AccessibilityContext } from "../context/AccessibilityContext";
import AccessibilityPopover from "../components/AccessibilityPopover";

function Home() {
  const content = useContent();
  const { accessibilityOptions } = useContext(AccessibilityContext);
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme => theme.breakpoints.down('lg'));

  if (!content) {
    return <LoadingAnimation />;
  }

  return (
    <Box sx={{
      overflow: "hidden",
      filter: accessibilityOptions.filter, // Apply grayscale
      backgroundColor: accessibilityOptions.contrast === "high" ? "#000" : accessibilityOptions.contrast === "low" ? "#DDD" : "#transparent",
    }}>

      {/* Accessibility Controls */}
      <Portal>
        <Box sx={{ position: "fixed", top: 200, right: 0, zIndex: 2000 }}>
          <AccessibilityPopover content={content.accessibility} />
        </Box>
      </Portal>

      <Navbar content={content.navbar} isMobile={isMobile} accessibilityOptions={accessibilityOptions} />
      <FloatingBar content={content} isMobile={isMobile} />
      <Grid container>
        <Grid item xs={12}>
          <HeroBanner content={content.heroBanner} isMobile={isMobile} accessibilityOptions={accessibilityOptions} />
        </Grid>
        <Grid item id="about" xs={12}>
          <About content={content.about} isMobile={isMobile} isTablet={isTablet} accessibilityOptions={accessibilityOptions} />
        </Grid>
        <Grid item id="scopes" xs={12}>
          <Scopes content={content.scope} isMobile={isMobile} accessibilityOptions={accessibilityOptions} />
        </Grid>
        <Grid item id="companies" xs={12}>
          <Companies content={content.companies} isMobile={isMobile} accessibilityOptions={accessibilityOptions} />
        </Grid>
        <Grid
          item
          id="contactme"
          xs={12}
          sx={{ bgcolor: accessibilityOptions.contrast === "high" ? "#000" : accessibilityOptions.contrast === "low" ? "#C2D3FC" : "rgba(74, 105, 123, 0.1)" }}
        >
          <ContactMe content={content.contactMe} isMobile={isMobile} accessibilityOptions={accessibilityOptions} />
        </Grid>
        <Grid item id="footer" xs={12}>
          <Footer content={content} isMobile={isMobile} />
        </Grid>
      </Grid>
    </Box>
  );
}

export default Home;
