import React, { useState } from "react";
import { IconButton, Box, Slide } from "@mui/material";
import AccessibilityNewIcon from "@mui/icons-material/AccessibilityNew";
import AccessibilityMenu from "./AccessibilityMenu";

const MENU_WIDTH = 220;

const AccessibilityPopover = ({ content }) => {
  const [open, setOpen] = useState(false);

  const handleButtonClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <IconButton
        onClick={handleButtonClick}
        sx={{
          backgroundColor: "#0F3460",
          width: "40px",
          height: "40px",
          borderRadius: "8px 0 0 8px",
          color: "#FFF",
          "&:hover": {
            backgroundColor: "#0F3460",
          },
          transition: "transform 0.25s",
          transform: open ? `translateX(${-MENU_WIDTH}px)` : "none",
          position: "fixed",
          right: 0,
          top: "30%",
          zIndex: 2001,
          boxShadow: "0 2px 8px rgba(0,0,0,0.2)",
          "&::before": {
            content: '"נגישות"',
            position: "absolute",
            right: "45px",
            backgroundColor: "#0F3460",
            color: "#FFF",
            padding: "4px 8px",
            borderRadius: "4px",
            fontSize: "14px",
            opacity: 0,
            transition: "opacity 0.3s",
            whiteSpace: "nowrap",
          },
          "&:hover::before": {
            opacity: 1,
          },
        }}
      >
        <AccessibilityNewIcon />
      </IconButton>

      <Slide direction="right" in={open} mountOnEnter unmountOnExit>
        <Box
          sx={{
            position: "fixed",
            right: 0,
            top: "20%",
            width: MENU_WIDTH,
            backgroundColor: "white",
            borderRadius: "8px 0 0 8px",
            boxShadow: "0 2px 8px rgba(0,0,0,0.2)",
            border: "1px solid #E0E0E0",
            height: "auto",
            maxHeight: "80vh",
            overflowY: "auto",
            zIndex: 2000,
          }}
        >
          <AccessibilityMenu content={content} />
        </Box>
      </Slide>
    </>
  );
};

export default AccessibilityPopover;
