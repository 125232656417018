import React, { createContext, useState, useMemo } from "react";
import createCustomTheme from "../theme/theme"; // Import theme function
import { ThemeProvider } from "@mui/material/styles";

export const AccessibilityContext = createContext();

export const AccessibilityProvider = ({ children }) => {
    const [accessibilityOptions, setAccessibilityOptions] = useState({
        scalingFactor: 1,
        filter: "none",
        contrast: "normal",
        highlightLinks: false,
        fontFamily: "inherit",
    });

    const theme = useMemo(() => {
        const { scalingFactor, filter, contrast, fontFamily } = accessibilityOptions;
        return createCustomTheme(scalingFactor, filter, contrast, fontFamily);
    }, [accessibilityOptions]);

    const updateOptions = (newOptions) => {
        setAccessibilityOptions((prev) => 
            typeof newOptions === "function" ? newOptions(prev) : { ...prev, ...newOptions }
        );
    };

    return (
        <AccessibilityContext.Provider value={{ accessibilityOptions, updateOptions }}>
            <ThemeProvider theme={theme}>
                {children}
            </ThemeProvider>
        </AccessibilityContext.Provider>
    );
};
