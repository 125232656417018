import React, { useCallback, useMemo } from "react";
import { Box, Typography } from "@mui/material";
import { useDropzone } from "react-dropzone";
import InputField from "../components/reusable/InputField";

function EmployeeForm({
  onChange,
  uploadedFile,
  setUploadedFile,
  formData,
  errors,
  setErrors,
  formKey,
  isMobile,
  accessibilityOptions,
}) {

  const allowedFileTypes = useMemo(
    () => [
      "application/pdf",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/rtf",
      "application/vnd.oasis.opendocument.text",
    ],
    []
  );

  const maxSize = 4 * 1024 * 1024; // 4MB

  const onDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file) {
        if (!allowedFileTypes.includes(file.type)) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            cv: "סוג קובץ לא תקין. הקובץ חייב להיות בפורמט: .pdf, .doc, .docx, .rtf, .odt",
          }));
          return;
        }
        if (file.size > maxSize) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            cv: "גודל קובץ גדול מדי. הקובץ חייב להיות פחות מ-4MB",
          }));
          return;
        }
        setErrors((prevErrors) => ({ ...prevErrors, cv: null }));
        setUploadedFile(file);
      }
    },
    [setUploadedFile, allowedFileTypes, maxSize, setErrors]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <form
      id="employee_form"
      key={formKey}
      style={{
        display: "flex",
        height: "100%",
        flexDirection: "column",
        justifyContent: "space-around",
      }}
    >
      <InputField
        name="fullName"
        label="שם מלא"
        value={formData.fullName || ""}
        onChange={onChange}
        sx={{ width: isMobile ? "90%" : "500px", mx: isMobile ? 4.5 : 2 }}
        error={Boolean(errors.fullName)}
        helperText={errors.fullName}
        accessibilityOptions={accessibilityOptions}
      />
      <Box
        sx={{
          mx: 2,
          my: 3,
          width: "500px",
          display: isMobile ? "flex" : "inline-flex",
          flexDirection: isMobile ? "column" : "row",
          flexWrap: "wrap",
          gap: "20px",
        }}
      >
        <Box>
          <InputField
            name="contactNumber"
            label="מספר נייד"
            value={formData.contactNumber || ""}
            onChange={onChange}
            error={Boolean(errors.contactNumber)}
            helperText={errors.contactNumber}
            sx={{ width: isMobile ? "70%" : "240px", mx: isMobile ? 2.5 : 0 }}
            accessibilityOptions={accessibilityOptions}
          />
        </Box>
        <Box>
          <InputField
            name="contactEmail"
            label="כתובת אימייל"
            value={formData.contactEmail || ""}
            onChange={onChange}
            error={Boolean(errors.contactEmail)}
            helperText={errors.contactEmail}
            sx={{ width: isMobile ? "70%" : "240px", mx: isMobile ? 2.5 : 0 }}
            accessibilityOptions={accessibilityOptions}
            />
        </Box>
      </Box>
      <Box
        sx={{
          mx: 2,
          width: "500px",
          display: isMobile ? "flex" : "inline-flex",
          flexDirection: isMobile ? "column" : "row",
          flexWrap: "wrap",
          gap: "20px",
        }}
      >
        <Box>
          <InputField
            name="desiredPosition"
            label="משרה רצויה"
            value={formData.desiredPosition || ""}
            onChange={onChange}
            error={Boolean(errors.desiredPosition)}
            helperText={errors.desiredPosition}
            sx={{ width: isMobile ? "70%" : "240px", mx: isMobile ? 2.5 : 0 }}
            accessibilityOptions={accessibilityOptions}
          />
        </Box>
        <Box>
          <InputField
            name="city"
            label="עיר מגורים"
            value={formData.city || ""}
            onChange={onChange}
            error={Boolean(errors.city)}
            helperText={errors.city}
            sx={{ width: isMobile ? "70%" : "240px", mx: isMobile ? 2.5 : 0 }}
            accessibilityOptions={accessibilityOptions}
          />
        </Box>
      </Box>

      {!uploadedFile && (
        <Box
          {...getRootProps()}
          sx={{
            width: isMobile ? "90%" : "500px",
            mx: isMobile ? 4.5 : 2,
            my: 3,
            p: "20px",
            border: "2px dashed gray",
            textAlign: "center",
            cursor: "pointer",
            bgcolor: "white",
          }}
        >
          <input {...getInputProps()} />
          {isDragActive ? (
            <p>שחרר את הקובץ כאן ...</p>
          ) : (
            <p>גרור ושחרר כאן את קובץ קורות החיים שלך, או לחץ לבחירת קובץ</p>
          )}
        </Box>
      )}
      {uploadedFile && (
        <Box sx={{ mt: 2, textAlign: "center" }}>
          <Typography variant="h6">הקובץ הועלה:</Typography>
          <a
            href={URL.createObjectURL(uploadedFile)}
            download={uploadedFile.name}
            target="_blank"
            rel="noopener noreferrer"
          >
            לחץ כאן לפתיחת הקובץ
          </a>
        </Box>
      )}
      {errors.cv && (
        <Box sx={{ mx: 2, my: 2, color: "red", textAlign: "center" }}>
          {errors.cv}
        </Box>
      )}
      <InputField
        label="תוכן ההודעה"
        name="msgContent"
        value={formData.msgContent || ""}
        multiline
        rows={3}
        onChange={onChange}
        sx={{ width: isMobile ? "90%" : "500px", mx: isMobile ? 4.5 : 2 }}
        accessibilityOptions={accessibilityOptions}
      />
    </form>
  );
}

export default EmployeeForm;
