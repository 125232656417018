import { createTheme } from "@mui/material/styles";

const createCustomTheme = (scalingFactor = 1, filter = "none", contrast = "normal", fontFamily = "Varela Round") =>
  createTheme({
    palette: {
      primary: { main: "#577CF9" },
      secondary: { main: "#9FAFB8" },
    },
    typography: {
      fontFamily: fontFamily === "readable" ? "Arial, sans-serif" : "Varela Round",
      h1: {
        color: contrast === "high" ? "#40C090" : contrast === "low" ? "#666666" : "#FFF",
        fontSize: `${4.3 * scalingFactor}rem`,
        fontWeight: "bold",
        fontStyle: "italic",
      },
      h2: {
        color: contrast === "high" ? "#40C090" : contrast === "low" ? "#666666" : "#222",
        fontSize: `${4 * scalingFactor}rem`,
        fontWeight: "bold",
      },
      h3: {
        color: contrast === "high" ? "#40C090" : contrast === "low" ? "#666666" : "#FFF",
        fontSize: `${2 * scalingFactor}rem`,
      },
      h4: {
        color: contrast === "high" ? "#40C090" : contrast === "low" ? "#666666" : "#222",
        fontSize: `${1.7 * scalingFactor}rem`,
      },
      h5: {
        color: contrast === "high" ? "#40C090" : contrast === "low" ? "#666666" : "#FFF",
        fontSize: `${1.5 * scalingFactor}rem`,
      },
      h6: {
        color: contrast === "high" ? "#40C090" : contrast === "low" ? "#666666" : "#222",
        fontSize: `${1.3 * scalingFactor}rem`,
        fontWeight: "bold",
        padding: "5px 10px",
      },
      body1: {
        color: contrast === "high" ? "#40C090" : contrast === "low" ? "#666666" : "#666",
        fontSize: `${1 * scalingFactor}rem`,
      },
      body2: {
        color: contrast === "high" ? "#40C090" : contrast === "low" ? "#666666" : "#FFF",
        fontSize: `${1 * scalingFactor}rem`,
      },
      mobileH1: {
        color: contrast === "high" ? "#40C090" : contrast === "low" ? "#666666" : "#FFF",
        fontSize: `${2.5 * scalingFactor}rem`,
        fontWeight: "bold",
        fontStyle: "italic",
      },
      mobileH2: {
        color: contrast === "high" ? "#40C090" : contrast === "low" ? "#666666" : "#222",
        fontSize: `${2 * scalingFactor}rem`,
        fontWeight: "bold",
      },
      mobileH3: {
        color: contrast === "high" ? "#40C090" : contrast === "low" ? "#666666" : "#FFF",
        fontSize: `${1.3 * scalingFactor}rem`,
      },
      mobileH4: {
        color: contrast === "high" ? "#40C090" : contrast === "low" ? "#666666" : "#222",
        fontSize: `${1.2 * scalingFactor}rem`,
      },
      mobileH5: {
        color: contrast === "high" ? "#40C090" : contrast === "low" ? "#666666" : "#FFF",
        fontSize: `${1.1 * scalingFactor}rem`,
      },
      mobileH6: {
        color: contrast === "high" ? "#40C090" : contrast === "low" ? "#666666" : "#222",
        fontSize: `${1 * scalingFactor}rem`,
        fontWeight: "bold",
        padding: "5px 10px",
      },
      mobileBody1: {
        color: contrast === "high" ? "#40C090" : contrast === "low" ? "#666666" : "#666",
        fontSize: `${0.9 * scalingFactor}rem`,
      },
      mobileBody2: {
        color: contrast === "high" ? "#40C090" : contrast === "low" ? "#666666" : "#FFF",
        fontSize: `${0.85 * scalingFactor}rem`,
      },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            filter: filter,
            backgroundColor: contrast === "low" ? "#F5F5F5" : "inherit",
          },
          a: {
            textDecoration: "none",
            backgroundColor: "transparent",
            fontWeight: "bold",
            transition: "background 0.3s",
            ...(filter !== "none" && { filter }),
            ...(contrast === "high" && { textShadow: "1px 1px 2px #000" }),
          },
        },
      },
    },
  });

export default createCustomTheme;
