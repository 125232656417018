import React from "react";
import { Box, Typography, Container } from "@mui/material";
import backgroundImage from "../Images/PartnersWalkingAway_1920x1080.mp4";

function About({ content, isMobile, isTablet, accessibilityOptions }) {
  return (
    <Box
      sx={{
        backgroundColor: accessibilityOptions.contrast === "high" ? "#000" : accessibilityOptions.contrast === "low" ? "#C2D3FC" : "#5B7E99",
        position: "relative",
        zIndex: accessibilityOptions.contrast === "normal" ? -1 : 1,
        minHeight: "650px",
      }}
    >
      {(!isMobile && !isTablet && accessibilityOptions.contrast !== "high" && accessibilityOptions.contrast !== "low") && (
        <video
          autoPlay
          loop
          muted
          style={{
            opacity: 0.2,
            position: "absolute",
            zIndex: -1,
            width: "100%",
            minHeight: "740px",
          }}
        >
          <source src={backgroundImage} type="video/mp4" />
        </video>
      )}
      <Container>
        <Box
          sx={{
            px: isMobile ? 0 : 10,
            py: 6,
            m: "0 auto",
            width: "80%",
            zIndex: 1,
          }}
        >
          <Typography
            variant={isMobile ? "mobileH2" : "h2"}
            align="center"
            position={"relative"}
            display={"block"}
            sx={{
              pb: isMobile ? 2 : 4,
            }}
          >
            {content.title}
          </Typography>
          <Typography
            variant={isMobile ? "mobileH3" : "h3"}
            display={"block"}
            gutterBottom
            sx={{
              textAlign: "center",
              maxWidth: "765px",
              fontStyle: "italic",
            }}
          >
            {content.founder}
          </Typography>
          <Typography
            variant={isMobile ? "mobileH3" : "h3"}
            display={"block"}
            sx={{
              textAlign: "center",
              maxWidth: "765px",
              fontStyle: "italic",
              pb: isMobile ? 2 : 4,
            }}
          >
            {content.work_with}
          </Typography>
          <Typography
            variant={isMobile ? "mobileH4" : "h4"}
            gutterBottom
            sx={{
              fontWeight: "bold",
            }}
          >
            {content.guidelines.title}
          </Typography>
          {content.guidelines.content.map((guideline, index) => (
            <Box display={"flex"} key={index}>
              <Typography
                component="span"
                variant={isMobile ? "mobileH5" : "h5"}
                sx={{
                  pl: isMobile ? 0 : 2,
                }}
              >
                {index + 1}.
              </Typography>
              <Box
                sx={{
                  pl: 1,
                }}
              >
                <Typography
                  component="span"
                  variant={isMobile ? "mobileH5" : "h5"}
                  sx={{
                    fontWeight: "bold",
                    pr: 1,
                  }}
                >
                  {guideline.bold}
                </Typography>
                <Typography
                  component="span"
                  variant={isMobile ? "mobileH5" : "h5"}
                >
                  {guideline.text}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </Container>
    </Box>
  );
}

export default About;
