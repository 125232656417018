import React, { useEffect, useState, useCallback } from "react";
import { Typography, Box } from "@mui/material";
import { styled } from "@mui/system";
import ups from "../Images/company-logos/ups.png";
import siram from "../Images/company-logos/siram.png";
import pasternakShoham from "../Images/company-logos/pasternak_shoham.png";
import orsher from "../Images/company-logos/orsher.png";
import sonigo from "../Images/company-logos/sonigo.png";
import amisraGas from "../Images/company-logos/amisra_gas.png";
import teatronHashaa from "../Images/company-logos/teatron_hashaa.png";
import tdyCargo from "../Images/company-logos/tdy_cargo.png";
import flyingCargo from "../Images/company-logos/flying_cargo.png";
import movement from "../Images/company-logos/movement.png";
import dalas from "../Images/company-logos/dalas.png";

const rectImageHeight = "80px";
const squareImageHeight = "115px";
const company_logos = [
  { logo: amisraGas, alt: "אמישראגז", imageHeight: rectImageHeight },
  { logo: ups, alt: "ups", imageHeight: squareImageHeight },
  { logo: siram, alt: "S.I.R.A.M", imageHeight: rectImageHeight },
  { logo: pasternakShoham, alt: "פסטרנק שוהם", imageHeight: squareImageHeight },
  { logo: orsher, alt: "אורשר", imageHeight: rectImageHeight },
  { logo: sonigo, alt: "Sonigo", imageHeight: squareImageHeight },
  { logo: teatronHashaa, alt: "תיאטרון השעה הישראלי", imageHeight: squareImageHeight },
  { logo: tdyCargo, alt: "TDY Cargo", imageHeight: rectImageHeight },
  { logo: flyingCargo, alt: "Flying Cargo", imageHeight: squareImageHeight },
  { logo: movement, alt: "Movement", imageHeight: rectImageHeight },
  { logo: dalas, alt: "Dalas", imageHeight: rectImageHeight },
];
const scrollSpeed = 50; // Speed of the scroll (higher is slower)

const Container = styled(Box)`
  display: flex;
  overflow: hidden;
  width: 85%;
  padding: 0;
  border-bottom: 2px solid black;
  border-top: 2px solid black;
`;

const ScrollWrapper = styled(Box)`
  display: flex;
  animation: scroll ${scrollSpeed}s linear infinite;
  width: max-content;

  &:hover {
    animation-play-state: paused;
  }

  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100%);
    }
  }
`;

const ImageContainer = styled(Box)`
  min-width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 20px 0;
`;

const useImageLoader = (images, timeout = 5000) => {
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const loadedImagesRef = React.useRef(0);

  const resetLoader = useCallback(() => {
    loadedImagesRef.current = 0;
    setImagesLoaded(false);
    setShouldRefresh(false);
  }, []);

  const handleImageLoad = useCallback(() => {
    loadedImagesRef.current += 1;
    if (loadedImagesRef.current === images.length) {
      setImagesLoaded(true);
    }
  }, [images.length]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (!imagesLoaded) {
        setShouldRefresh(true);
      }
    }, timeout);

    return () => clearTimeout(timeoutId);
  }, [imagesLoaded, timeout]);

  useEffect(() => {
    if (shouldRefresh) {
      resetLoader();
      // Pre-load images
      images.forEach(img => {
        const image = new Image();
        image.src = img.logo;
        image.onload = handleImageLoad;
      });
    }
  }, [shouldRefresh, images, handleImageLoad, resetLoader]);

  return { imagesLoaded, handleImageLoad };
};

const Companies = ({ content, isMobile, accessibilityOptions }) => {
  const { imagesLoaded, handleImageLoad } = useImageLoader(company_logos);

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      minHeight={isMobile ? "250px" : "380px"}
      sx={{
        backgroundColor: accessibilityOptions.contrast === "low" ? "#C2D3FC" : "rgba(74, 105, 123, 0.1)",
      }}
    >
      <Typography
        variant={isMobile ? "mobileH2" : "h2"}
        sx={{
          pb: isMobile ? 2 : 10,
        }}
      >
        {content.title}
      </Typography>
      <Container>
        <ScrollWrapper style={{ visibility: imagesLoaded ? 'visible' : 'hidden' }}>
          <ImageContainer>
            {company_logos.map((company, index) => (
              <img
                key={index}
                title={company.alt}
                src={company.logo}
                alt={company.alt}
                height={company.imageHeight}
                style={{ objectFit: 'contain' }}
                onLoad={handleImageLoad}
              />
            ))}
          </ImageContainer>
          <ImageContainer>
            {company_logos.map((company, index) => (
              <img
                key={`duplicate-${index}`}
                title={company.alt}
                src={company.logo}
                alt={company.alt}
                height={company.imageHeight}
                style={{ objectFit: 'contain' }}
                onLoad={handleImageLoad}
              />
            ))}
          </ImageContainer>
        </ScrollWrapper>
      </Container>
    </Box>
  );
};

export default Companies;
