import React from "react";
import { Box, Typography, Button } from "@mui/material";
import hero from "../Images/hero-optionals/hero.jpg";

function HeroBanner({ content, isMobile, accessibilityOptions }) {
  return (
    <Box
      sx={{
        position: "relative",
        height: isMobile ? "70vh" : "85vh",
        backgroundImage: accessibilityOptions.contrast !== "high" && accessibilityOptions.contrast !== "low" ? `url(${hero})` : "none",
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundColor: accessibilityOptions.contrast === "high" ? "#000" : accessibilityOptions.contrast === "low" ? "#C2D3FC" : "transparent",
        display: "flex",
        minHeight: isMobile ? "300px" : "850px",
        "&::before": {
          content: '""',
          position: "absolute",
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          background:
            accessibilityOptions.contrast !== "high" && accessibilityOptions.contrast !== "low" ? "linear-gradient(rgba(0, 30, 300, 0.3), rgba(0, 0, 0, 0.4))" : "none",
          zIndex: 1,
        },
        zIndex: 0,
      }}
    >
      <Box sx={{ zIndex: 2 }}>
        <Typography variant={isMobile ? "mobileH1" : "h1"} position={"absolute"} top={"40%"} left={"5%"}>
          {content.title1}
        </Typography>
        <Typography variant={isMobile ? "mobileH1" : "h1"} position={"absolute"} top={"50%"} left={"20%"}>
          {content.title2}
        </Typography>
      </Box>
      <Box sx={{ zIndex: 2 }} position={"absolute"} top={"35%"} left={"38%"}>
        <Button
          variant="contained"
          sx={{
            mt: isMobile ? 25 : 30,
            pr: isMobile ? 5 : 10,
            pl: isMobile ? 5 : 10,
            pb: 2,
            pt: 2,
            backgroundColor: accessibilityOptions.contrast === "high" ? "#000" : accessibilityOptions.contrast === "low" ? "#C2D3FC" : "rgba(209, 49, 36, 0.9)",
            border: accessibilityOptions.contrast === "high" ? "1px solid #40C090" : accessibilityOptions.contrast === "low" ? "1px solid #666666" : "none",
            // backgroundColor: "rgba(209, 49, 36, 0.9)",
            textDecoration: accessibilityOptions.highlightLinks ? "underline" : "none",
            "&:hover": {
              backgroundColor: accessibilityOptions.contrast === "high" ? "#000" : accessibilityOptions.contrast === "low" ? "#C2D3FC" : "rgba(209, 49, 36, 0.9)",
              transform: "scale(1.1)",
              boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.5)",
            },
          }}
          type="submit"
          onClick={() => {
            const contactMe = document.getElementById("contactme");
            window.scrollTo({
              top: contactMe.offsetTop,
              behavior: "smooth",
            });
          }}
        >
          <Typography 
            variant={isMobile ? "mobileH5" : "h5"}
            sx={{
              textDecoration: accessibilityOptions.highlightLinks ? "underline" : "none",
            }}
          >
            {content.buttonContent}
          </Typography>
        </Button>
      </Box>
    </Box>
  );
}

export default HeroBanner;
